<template>
  <v-dialog
    v-model="dialog"
    width="400"
    min-width="400"
    height="200"
    min-height="200"
    persistent
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip transition="fade-transition" top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            class="ml-1"
            icon
            small
            depressed
            v-on="{ ...dialog, ...tooltip }"
            v-bind="btnProps"
            @click="getPhones()"
          >
            <v-icon small> mdi-phone-forward </v-icon>
            Assign
          </v-btn>
        </template>

        <span>Assign Phone</span>
      </v-tooltip>
    </template>
    <v-card min-width="400" min-height="200">
      <v-toolbar dark color="primary">
        Assign Phone to: {{ employe.fullname }}
      </v-toolbar>
      <v-card-text class="mt-2">
        <template v-if="loading">
          <div class="d-flex justify-center ma-4">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-else>
          <v-autocomplete
            :items="phonesFree"
            item-text="number"
            item-value="uuid"
            v-model="numberUuid"
            label="Phone Number"
            dense
            outlined
          ></v-autocomplete>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()" color="error">Cancel</v-btn>
        <v-btn text @click="assignNumber()" color="success">Assign</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { mapActions, mapState } from "vuex";
export default {
  name: "btn-asign-numb",
  props: {
    employe: {
      type: Object,
      default: () => ({}),
    },
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      numberUuid: "",
    };
  },
  computed: {
    ...mapState("crmConfigurationsModule", ["phonesFree", "loading"]),
  },

  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actGetNumbers",
      "actAssignNumber",
    ]),

    async getPhones() {
      await this.actGetNumbers();
    },

    async assignNumber() {
      const body = {
        numberUuid: this.numberUuid,
        employeeUuid: this.employe.uuid,
      };
      await this.actAssignNumber(body);
      this.$emit("assigned");
      this.close();
    },

    close() {
      this.dialog = false;
      this.numberUuid = "";
    },
  },
};
</script>
<style lang="scss" scope></style>
