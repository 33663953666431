
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import NotRecords from "@/components/NotRecords.vue";

import BtnAddHours from "@/views/Employees/buttons/BtnAddHours.vue";
import BtnGeneratePin from "@/views/Employees/buttons/BtnGeneratePin.vue";
import BtnAsignNum from "@/views/Employees/buttons/BtnAsignNum.vue";
import { MUT_RANGES } from "@/store/mutations/types";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { rolIcon, rolColor, statusColor } from "@/utils/rolesStatus";

import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyInfo,
  notifyError,
  notifySuccess,
} from "@/components/Notification";
import moment from "moment";
import { funtionsPhone } from "@/utils/mixins";
export default Vue.extend({
  name: "employees",
  mixins: [funtionsPhone],
  components: {
    BtnAddHours,
    NotRecords,

    BtnGeneratePin,

    BtnAsignNum,
  },
  data() {
    return {
      errorMessage: "",
      rules: {
        required: rules.required,
      },
      formValid: false,
      options: {},
      inspect: false,
      impersonate: {
        loading: false,
        dialogImpersonate: false,
        item: { employeeUuid: "", impersonateMinutesTime: 0 },
      },
      totalEmployees: 0,
      footerProps: { "items-per-page-options": [10, 15, 25, 50, 100, 1000] },
      showErrors: false,
      expanded: [],
      // The uuid of the worker to remove
      currentWorker: {
        fullname: "",
        phone: "",
        email: "",
        uuid: "",
      },
      dialogEdit: false,
      dialogConfirmRemove: false,
      dialogConfirmdisable: false,
      dialogConfirmenable: false,
      loadingaction: false,
      showDetails: false,
      reqrange: {
        limit: 10,
        offset: 0,
      },
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers", "loading", "totalworkers"]),
    ...mapGetters(["isSuper", "isAdmin"]),
    ...mapState(["dataCRM", "profile"]),
    ...mapGetters({ methodCall: "getMethodCalls" }),
    headers() {
      let head = [];
      if (this.methodCall != "twilio") {
        head = [
          // { text: "Avatar", value: "avatar", sortable: false },
          {
            text: this.$t("labelFullName"),
            value: "fullname",
            sortable: false,
          },
          {
            text: this.$t("labelUsername"),
            value: "username",
            sortable: false,
          },
          { text: "Rol", value: "rol", sortable: false },
          // { text: "Email", value: "email" },
          { text: "Status", value: "agentStatus", sortable: false },
          { text: this.$t("labelPhone"), value: "origPhone", sortable: false },
          { text: "Ext", value: "ext", sortable: false },
          { text: "USD/H", value: "payXhour", sortable: false },
          {
            text: "Hours to work",
            value: "hoursToWork",
            align: "center",
            sortable: false,
          },
          {
            text: "Start Time",
            value: "startDate",
            align: "center",
            sortable: false,
          },
          {
            text: "End Time",
            value: "endDate",
            align: "center",
            sortable: false,
          },
          { text: "Pin", value: "pin", align: "center", sortable: false },
          { text: "", value: "actions", sortable: false },
          { text: "", value: "data-table-expand" },
        ];
      } else {
        head = [
          // { text: "Avatar", value: "avatar", sortable: false },
          {
            text: this.$t("labelFullName"),
            value: "fullname",
            sortable: false,
          },
          {
            text: this.$t("labelUsername"),
            value: "username",
            sortable: false,
          },
          { text: "Rol", value: "rol", sortable: false },
          // { text: "Email", value: "email" },
          { text: "Status", value: "agentStatus", sortable: false },
          { text: this.$t("labelPhone"), value: "phone", sortable: false },
          { text: "USD/H", value: "payXhour", sortable: false },
          {
            text: "Hours to work",
            value: "hoursToWork",
            align: "center",
            sortable: false,
          },
          {
            text: "Start Time",
            value: "startDate",
            align: "center",
            sortable: false,
          },
          {
            text: "End Time",
            value: "endDate",
            align: "center",
            sortable: false,
          },
          { text: "Pin", value: "pin", align: "center", sortable: false },
          { text: "", value: "actions", sortable: false },
          { text: "", value: "data-table-expand" },
        ];
      }
      return head;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        (this as any).requestWorkers();
      }
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actRemoveEmployee",
      "actGeneratePin",
    ]),
    ...mapMutations(["mutReqRange", "updateStatus"]),
    ...mapActions(["impersonateUser", "actGeneralConfig"]),

    createWorker() {
      this.$router.push(`/employees/create`);
    },
    gotoEmployee(item: any) {
      this.$router.push(`/employees/edit/${item.uuid}`);
    },
    requestWorkers(): void {
      const { page, itemsPerPage }: any = (this as any).options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      (this as any).reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      (this as any).mutReqRange((this as any).reqrange);
      (this as any).actGetEmployees((this as any).reqrange);
    },
    setWorkerToRemove(worker: any) {
      (this as any).currentWorker = worker;
      (this as any).dialogConfirmRemove = true;
    },
    setWorkerToDisable(worker: any) {
      (this as any).currentWorker = worker;
      (this as any).dialogConfirmdisable = true;
    },
    setWorkerToEnable(worker: any) {
      (this as any).currentWorker = worker;
      (this as any).dialogConfirmenable = true;
    },
    setWorkerToEdit(worker: any) {
      (this as any).currentWorker = worker;
      (this as any).dialogEdit = true;
    },
    setWorkerToImpersonate(item: any) {
      (this as any).currentWorker = item;
      (this as any).impersonate = {
        dialogImpersonate: true,
        item: { employeeUuid: item.uuid, impersonateMinutesTime: 0 },
      };
    },
    getInfo(item: any) {
      this.$router.push("/employees/details/" + item.uuid);
    },
    cancelAll() {
      (this as any).dialogEdit = false;
      (this as any).loadingaction = false;
      (this as any).dialogConfirmRemove = false;
      (this as any).dialogConfirmdisable = false;
    },
    async confirmImpersonate() {
      const body = (this as any).impersonate.item;
      body.impersonateMinutesTime = Number(body.impersonateMinutesTime);
      (this as any).impersonate.loading = true;
      await (this as any).impersonateUser(body);

      await (this as any).actGeneralConfig();

      (this as any).impersonate.loading = false;
      this.$vuetify.theme.themes.light = this.dataCRM.themes.light;
      this.$router.push("/clients");
    },

    getStartTime(date: any) {
      if (date == null) {
        return "-";
      }
      const tim = moment(date);
      return tim.format("HH:mm");
    },
    cancelImpersonate() {
      (this as any).currentWorker = {
        fullname: "",
        phone: "",
        email: "",
        uuid: "",
      };
      (this as any).impersonate = {
        dialogImpersonate: false,
        item: { employeeUuid: "", impersonateMinutesTime: 0 },
      };
    },

    async remove() {
      try {
        (this as any).loadingaction = true;
        await getAPI.delete(
          "/users/delete/" + (this as any).currentWorker.uuid
        );
        (this as any).dialogConfirmRemove = false;
        (this as any).loadingaction = false;
        (this as any).requestWorkers();
        notifySuccess(this.$t("notification"));
      } catch (error: any) {
        if (error.response) {
          (this as any).loadingaction = false;
          notifyError(error.response.data.message.toString());
        }
        (this as any).dialogConfirmRemove = false;
      }
    },
    async disableWorker(status: string) {
      try {
        (this as any).loadingaction = true;
        await getAPI.post("/users/update/" + (this as any).currentWorker.uuid, {
          status,
        });
        (this as any).dialogConfirmdisable = false;
        (this as any).dialogConfirmenable = false;
        (this as any).loadingaction = false;
        (this as any).requestWorkers();
        const not =
          status == "DISABLED"
            ? this.$t("notification1")
            : this.$t("notification2");
        notifySuccess(not);
      } catch (error: any) {
        if (error.response) {
          (this as any).loadingaction = false;
          notifyError(error.response.data.message.toString());
        }
        (this as any).dialogConfirmdisable = false;
        (this as any).dialogConfirmenable = false;
      }
    },
    setEmployeDetails(employee: any) {
      (this as any).currentWorker = employee;
      (this as any).showDetails = true;
    },
    rolIcon,
    rolColor,
    statusColor,
  },
  mounted() {
    if (localStorage.getItem("impersonate")) {
      (this as any).inspect = true;
    }
  },
});
